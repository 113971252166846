import React from 'react'

import Layout from "../components/layout"
import SEO from "../components/seo"

const ApplicationsPage = ({data}) => {

    
    return (

        <Layout pageClass='applications-page'>
        <SEO title="Applications" />
            <main className='main'>
                <div className='typography inner'>
                    <h1>Applications</h1>
                    <div className="application-holder">
                        {data.allFile.nodes.map((e, i) => 
                            <div className="application-image" key={"app-image-"+i}>
                                <img src={e.childImageSharp.fluid.src} alt={e.base}/>
                            </div>
                        )}
                        
                    </div>
                </div>
            </main> 
        </Layout>

    )
}

export const query = graphql`
query {
    allFile(filter: {relativeDirectory: {eq: "applications"}, ext: {regex: "/(jpg)|(jpeg)|(png)/"}}, sort: {fields: sourceInstanceName}) {
      nodes {
        base
        childImageSharp {
            
          fluid(maxWidth: 275, maxHeight: 275) {
            src
            
          }
        }
      }
    }
  }`
  
export default ApplicationsPage